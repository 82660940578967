import React from "react";
import { Outlet } from "react-router-dom";

function Admin() {
	return (
		<div>
			<h1>Admin Panel</h1>
			<Outlet />
		</div>
	);
}

export default Admin;
