import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../layouts/App';
import Home from '../pages/main/Home';
import About from '../pages/main/About';
import Input from '../pages/main/Input';
import DiabetesHealthJournal from '../pages/main/DiabetesHealthJournal';
import NotFound from '../pages/NotFound';
import Admin from '../layouts/Admin';
import AdminHome from '../pages/AdminHome';
import AdminDashboard from '../pages/AdminDashboard';
import AdminSettings from '../pages/AdminSettings';
import Login from '../pages/Login';
import ProtectedRoute from '../utils/ProtectedRoute';

const isAuthenticated = false; // 실제 인증 로직을 여기에 추가하세요.

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: "/", element: <Home /> },
            { path: "about", element: <About /> },
            { path: "input", element: <Input /> },
            { path: "Journal", element: <DiabetesHealthJournal /> },
            { path: "login", element: <Login /> },
        ],
    },
    {
        path: "/admin",
        element: <ProtectedRoute element={<Admin />} isAuthenticated={isAuthenticated} />,
        children: [
            { index: true, element: <AdminHome /> },
            { path: "dashboard", element: <AdminDashboard /> },
            { path: "settings", element: <AdminSettings /> },
        ],
    },
    {
        path: "*",
        element: <NotFound />,
    },
]);

const Router = () => {
    return <RouterProvider router={router} />;
};

export default Router;