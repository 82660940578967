import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

function MealInfo({ handleMealInfoModalOpen }) {
	const handleClick = () => {
		console.log("식사정보 Card clicked!");
		handleMealInfoModalOpen();
	};

	return (
		<Card elevation={3}>
			<CardActionArea onClick={handleClick}>
				<CardMedia component='img' height='100' image='/assets/Meal.png' alt='Food(Meal)' sx={{ height: 'auto' }} />
				<CardContent>
					<Typography gutterBottom variant='h6' component='div'>
						식사 정보
					</Typography>
					<Typography variant='body2' sx={{ color: "text.secondary" }}>
						무엇을 드셨나요?
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default MealInfo;
