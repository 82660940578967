import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

function InsulinInjectionInfo({ handleInsulinInjectionInfoModalOpen }) {
	const handleClick = () => {
		console.log("인슐린 정보 Card clicked!");
		handleInsulinInjectionInfoModalOpen();
	};

	return (
		<Card elevation={3}>
			<CardActionArea onClick={handleClick}>
				<CardMedia component='img' height='100' image='/assets/InsulinIn.png' alt='InsulinIn' sx={{ height: 'auto' }} />
				<CardContent>
					<Typography gutterBottom variant='h6' component='div'>
						인슐린 정보
					</Typography>
					<Typography variant='body2' sx={{ color: "text.secondary" }}>
						아파요!!!
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default InsulinInjectionInfo;
