import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const mealOptions = [
	{ value: '아침', label: '아침' },
	{ value: '점심', label: '점심' },
	{ value: '저녁', label: '저녁' },
];

const MealInfoModalComponent = ({ open, handleClose }) => {
	const [mealType, setMealType] = useState('');
	const [mealContent, setMealContent] = useState('');
	const [calories, setCalories] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		// 식사 정보 제출 로직을 여기에 작성하세요.
		console.log({
			mealType,
			mealContent,
			calories,
		});
		handleClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={style}>
				<form onSubmit={handleSubmit}>
					<TextField
						select
						label="식사 종류"
						value={mealType}
						onChange={(e) => setMealType(e.target.value)}
						fullWidth
						margin="normal"
						required
					>
						{mealOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
					<TextField
						label="식사 내용"
						value={mealContent}
						onChange={(e) => setMealContent(e.target.value)}
						fullWidth
						margin="normal"
						required
					/>
					<TextField
						label="칼로리"
						type="number"
						value={calories}
						onChange={(e) => setCalories(e.target.value)}
						fullWidth
						margin="normal"
						required
					/>
					<Button type="submit" variant="contained" color="primary">
						제출
					</Button>
				</form>
			</Box>
		</Modal>
	);
};

export default MealInfoModalComponent;