import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

const Nav = ({ isAuthenticated, userName, onLogout }) => {
	const theme = useTheme();

	return (
		<NavContainer theme={theme}>
			<NavList>
				<NavItem><NavLink to="/">Home</NavLink></NavItem>
				{/* <NavItem><NavLink to="/about">건강관리</NavLink></NavItem> */}
				<NavItem><NavLink to="/input">입력</NavLink></NavItem>
				<NavItem><NavLink to="/Journal">당뇨수첩</NavLink></NavItem>
			</NavList>
			<UserSection>
				{isAuthenticated ? (
					<>
						<UserName>{userName}</UserName>
						<NavLink to="/" onClick={onLogout}>Logout</NavLink>
					</>
				) : (
					<>
						<UserName>{userName}</UserName>
						<NavLink to="/login">Login</NavLink>
					</>
				)}
			</UserSection>
		</NavContainer>
	);
};

const NavContainer = styled.nav`
    background-color: ${(props) => props.theme.palette.primary.main};
    padding: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NavList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const NavItem = styled.li`
    display: inline;
    margin-right: 1em;
`;

const NavLink = styled(Link)`
    color: white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const UserSection = styled.div`
    display: flex;
    align-items: center;
`;

const UserName = styled.span`
    color: white;
    margin-right: 1em;
`;

export default Nav;