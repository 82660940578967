import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MainTable from './MainTable';

const fetchData = () =>
	[
		{
			key: 1,
			date: '2021-10-01',
			min: 100,
			max: 200,
			inside: [
				{
					key: 1,
					meal: '아침',
					beforeAfter: '식전',
					value: 100,
					time: '08:00'
				},
				{
					key: 2,
					meal: '점심',
					beforeAfter: '식전',
					value: 150,
					time: '12:00'
				},
				{
					key: 3,
					meal: '점심',
					beforeAfter: '식후',
					value: 200,
					time: '18:00'
				}
			]
		},
		{
			key: 2,
			date: '2021-09-01',
			min: 199,
			max: 299,
			inside: [
				{
					key: 1,
					meal: '아침',
					beforeAfter: '식전',
					value: 100,
					time: '08:00'
				},
				{
					key: 2,
					meal: '점심',
					beforeAfter: '식전',
					value: 150,
					time: '12:00'
				},
				{
					key: 3,
					meal: '점심',
					beforeAfter: '식후',
					value: 200,
					time: '18:00'
				}
			]
		},
		{
			key: 3,
			date: '2021-08-01',
			min: 1,
			max: 2,
			inside: [
				{
					key: 1,
					meal: '아침',
					beforeAfter: '식전',
					value: 1,
					time: '08:00'
				},
				{
					key: 2,
					meal: '점심',
					beforeAfter: '식전',
					value: 2,
					time: '12:00'
				},
				{
					key: 3,
					meal: '점심',
					beforeAfter: '식후',
					value: 3,
					time: '13:00'
				}
				,
				{
					key: 4,
					meal: '저녁',
					beforeAfter: '식전',
					value: 300,
					time: '18:00'
				}
			]
		}
	]

function CollapsibleTable() {
	const [openAll, setOpenAll] = useState(false);
	const [openRows, setOpenRows] = useState({});

	const handleToggleAll = () => {
		const newOpenState = !openAll;
		setOpenAll(newOpenState);
		const newOpenRows = {};
		fetchData().forEach((row) => {
			newOpenRows[row.key] = newOpenState;
		});
		setOpenRows(newOpenRows);
	};

	const handleToggleRow = (id) => {
		setOpenRows((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell>
							<IconButton onClick={handleToggleAll}>
								<ExpandMoreIcon />
							</IconButton>
						</TableCell>
						<TableCell align="center">일자</TableCell>
						<TableCell align="center">최소 수치</TableCell>
						<TableCell align="center">최대 수치</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{fetchData().map((row) => (
						<MainTable key={row.key} data={row} open={openRows[row.key]} handleToggleRow={handleToggleRow} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default CollapsibleTable;