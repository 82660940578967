const diabetesMockData = [
	{ id: 1, date: "2023-10-01", time: "08:00", type: "식전", value: 110 },
	{ id: 2, date: "2023-10-01", time: "12:00", type: "식후", value: 140 },
	{ id: 3, date: "2023-10-02", time: "08:00", type: "식전", value: 115 },
	{ id: 4, date: "2023-10-02", time: "18:00", type: "식후", value: 130 },
	{ id: 5, date: "2023-10-03", time: "08:00", type: "식전", value: 120 },
	{ id: 6, date: "2023-10-03", time: "12:00", type: "식후", value: 145 },
	{ id: 7, date: "2023-10-15", time: "12:00", type: "식후", value: 145 },
];

const mockData2 = [
	{ id: 1, date: "2023-10-01", time: "08:00", type: "식전", value: 110 },
	{ id: 2, date: "2023-10-01", time: "12:00", type: "식후", value: 140 },
	{ id: 3, date: "2023-10-02", time: "08:00", type: "식전", value: 115 },
	{ id: 4, date: "2023-10-02", time: "18:00", type: "식후", value: 130 },
	{ id: 5, date: "2023-10-03", time: "08:00", type: "식전", value: 120 },
	{ id: 6, date: "2023-10-03", time: "12:00", type: "식후", value: 145 },
	{ id: 7, date: "2023-10-15", time: "12:00", type: "식후", value: 145 },
];

export { diabetesMockData, mockData2 };
