import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const mealOptions = [
	{ value: '아침 식전', label: '아침 식전' },
	{ value: '아침 식후', label: '아침 식후' },
	{ value: '점심 식전', label: '점심 식전' },
	{ value: '점심 식후', label: '점심 식후' },
	{ value: '저녁 식전', label: '저녁 식전' },
	{ value: '저녁 식후', label: '저녁 식후' },
];

const DiabetesValueModalComponent = ({ open, handleClose }) => {
	const [date, setDate] = useState('');
	const [meal, setMeal] = useState('');
	const [value, setValue] = useState('');
	const [time, setTime] = useState('');

	const handleSave = () => {
		// 입력 값을 처리하는 로직을 여기에 추가합니다.
		console.log({ date, meal, value, time });
		handleClose();
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box sx={style}>
				<h2 id="modal-title">데이터 입력</h2>
				<TextField
					label="날짜"
					type="date"
					value={date}
					onChange={(e) => setDate(e.target.value)}
					fullWidth
					margin="normal"
				/>
				<TextField
					select
					label="식사 전/후"
					value={meal}
					onChange={(e) => setMeal(e.target.value)}
					fullWidth
					margin="normal"
				>
					{mealOptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>

				<TextField
					label="수치"
					type="number"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					fullWidth
					margin="normal"
				/>
				<TextField
					label="시간"
					type="time"
					value={time}
					onChange={(e) => setTime(e.target.value)}
					fullWidth
					margin="normal"
				/>
				<Button variant="contained" color="primary" onClick={handleSave} fullWidth>
					저장
				</Button>
			</Box>
		</Modal>
	);
};

export default DiabetesValueModalComponent;