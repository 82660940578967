import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const InsulinInjectionInfoModalComponent = ({ open, handleClose }) => {
	const [injectionType, setInjectionType] = useState('');
	const [injectionAmount, setInjectionAmount] = useState('');
	const [injectionDate, setInjectionDate] = useState('');
	const [injectionTime, setInjectionTime] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		// 인슐린 주사 용량 제출 로직을 여기에 작성하세요.
		console.log({
			injectionType,
			injectionAmount,
			injectionDate,
			injectionTime,
		});
		handleClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={style}>
				<form onSubmit={handleSubmit}>
					<TextField
						select
						label="주사 종류"
						value={injectionType}
						onChange={(e) => setInjectionType(e.target.value)}
						fullWidth
						margin="normal"
						required
					>
						<MenuItem value="기저 인슐린">기저 인슐린</MenuItem>
						<MenuItem value="식사 인슐린">식사 인슐린</MenuItem>
					</TextField>
					<TextField
						label="주사 용량 (단위)"
						type="number"
						value={injectionAmount}
						onChange={(e) => setInjectionAmount(e.target.value)}
						fullWidth
						margin="normal"
						required
					/>
					<TextField
						label="일자"
						type="date"
						value={injectionDate}
						onChange={(e) => setInjectionDate(e.target.value)}
						fullWidth
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						required
					/>
					<TextField
						label="시간"
						type="time"
						value={injectionTime}
						onChange={(e) => setInjectionTime(e.target.value)}
						fullWidth
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						required
					/>
					<Button type="submit" variant="contained" color="primary">
						제출
					</Button>
				</form>
			</Box>
		</Modal>
	);
};

export default InsulinInjectionInfoModalComponent;