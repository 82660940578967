import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

function InputCard() {
	const handleClick = () => {
		console.log("투약 정보 Card clicked!");
		// 추가적인 클릭 이벤트 처리 로직을 여기에 작성하세요.
	};

	return (
		<Card elevation={3}>
			<CardActionArea onClick={handleClick}>
				<CardMedia component='img' height='100' image='./assets/Pill.png' alt='pill' sx={{ height: 'auto' }} />
				<CardContent>
					<Typography gutterBottom variant='h6' component='div'>
						투약 정보
					</Typography>
					<Typography variant='body2' sx={{ color: "text.secondary" }}>
						꾸울~~~꺽!
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default InputCard;
