import React, { useEffect, useState } from "react";
import axios from "axios";

function Home() {
	const [posts, setPosts] = useState([]);

	useEffect(() => {
		axios.get("https://jsonplaceholder.typicode.com/todos").then((response) => setPosts(response.data));
	}, []);

	return (
		<>
			<h2>Home Page</h2>
			<ul>
				{posts.map((post) => (
					// completed가 true이면 취소선을 긋는다.
					<li key={post.id} style={{ textDecoration: post.completed ? "line-through" : "none" }}>
						{post.title}
					</li>
				))}
			</ul>
		</>
	);
}

export default Home;
