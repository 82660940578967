import React, { useState, useEffect } from "react";
import {
	Container,
	Title,
	Form,
	FormGroup,
	Label,
	Input,
	Select,
	LargeInput,
	Button,
	Subtitle,
	List,
	ListItem,
	Type,
	Value,
	DateGroup,
	DateTitle,
	MinMaxValue,
	TimeGroup,
	TimeTitle,
	ButtonGroup,
	EditButton,
	DeleteButton,
} from "./About.styles";

import { diabetesMockData } from "../../mock-data/diabetes";

const About = () => {
	const [bloodSugar, setBloodSugar] = useState([]);
	const [value, setValue] = useState("");
	const [type, setType] = useState("식전");
	const [date, setDate] = useState("");
	const [time, setTime] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [editId, setEditId] = useState(null);
	const [collapsedDates, setCollapsedDates] = useState({});

	// 날짜시간 채우기 함수
	const fillDateTime = () => {
		const now = new Date();
		const formattedDate = now.toISOString().split("T")[0];
		const formattedTime = now.toTimeString().split(" ")[0].slice(0, 5);
		setDate(formattedDate);
		setTime(formattedTime);
	};

	useEffect(() => {
		fillDateTime();
		loadMockData();
	}, []);

	// 추후 이부분을 서버에서 가져오는 API로 대체
	const loadMockData = () => {
		setBloodSugar(diabetesMockData);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (editMode) {
			const updatedEntries = bloodSugar.map((entry) => (entry.id === editId ? { ...entry, value, type, date, time } : entry));
			setBloodSugar(updatedEntries);
			setEditMode(false);
			setEditId(null);
		} else {
			const newEntry = { value, type, date, time, id: Date.now() };
			setBloodSugar([...bloodSugar, newEntry]);
		}
		setValue("");
		fillDateTime();
	};

	const handleEdit = (entry) => {
		setEditMode(true);
		setEditId(entry.id);
		setValue(entry.value);
		setType(entry.type);
		setDate(entry.date);
		setTime(entry.time);
	};

	const handleDelete = (id) => {
		const updatedEntries = bloodSugar.filter((entry) => entry.id !== id);
		setBloodSugar(updatedEntries);
	};

	const toggleCollapse = (date) => {
		setCollapsedDates((prev) => ({
			...prev,
			[date]: !prev[date],
		}));
	};

	const groupByDate = (entries) => {
		const groupedEntries = entries.reduce((acc, entry) => {
			if (!acc[entry.date]) {
				acc[entry.date] = [];
			}
			acc[entry.date].push(entry);
			return acc;
		}, {});

		const sortedDates = Object.keys(groupedEntries).sort((a, b) => new Date(b) - new Date(a));
		const sortedEntries = sortedDates.reduce((acc, date) => {
			acc[date] = groupedEntries[date];
			return acc;
		}, {});

		return sortedEntries;
	};

	const groupByTime = (entries) => {
		const timeGroups = {
			아침: [],
			점심: [],
			저녁: [],
		};

		entries.forEach((entry) => {
			const hour = parseInt(entry.time.split(":")[0], 10);
			if (hour < 12) {
				timeGroups.아침.push(entry);
			} else if (hour < 18) {
				timeGroups.점심.push(entry);
			} else {
				timeGroups.저녁.push(entry);
			}
		});

		return timeGroups;
	};

	return (
		<Container>
			<Title>당뇨정보 Page</Title>
			<Form onSubmit={handleSubmit}>
				<FormGroup>
					<Label>
						날짜:
						<Input type='date' value={date} onChange={(e) => setDate(e.target.value)} required />
					</Label>
				</FormGroup>
				<FormGroup>
					<Label>
						시간:
						<Input type='time' value={time} onChange={(e) => setTime(e.target.value)} required />
					</Label>
				</FormGroup>
				<FormGroup>
					<Label>
						구분:
						<Select value={type} onChange={(e) => setType(e.target.value)}>
							<option value='식전'>식전</option>
							<option value='식후'>식후</option>
						</Select>
					</Label>
				</FormGroup>
				<FormGroup>
					<Label>
						당 수치:
						<LargeInput type='number' value={value} onChange={(e) => setValue(e.target.value)} required />
					</Label>
				</FormGroup>
				<Button type='submit'>{editMode ? "수정" : "추가"}</Button>
			</Form>
			<Subtitle>입력된 당 수치</Subtitle>
			<List>
				{Object.entries(groupByDate(bloodSugar)).map(([date, entries]) => {
					const minValue = Math.min(...entries.map((entry) => entry.value));
					const maxValue = Math.max(...entries.map((entry) => entry.value));
					const isToday = date === new Date().toISOString().split("T")[0];
					const isCollapsed = collapsedDates[date] ?? !isToday;

					return (
						<DateGroup key={date}>
							<DateTitle onClick={() => toggleCollapse(date)}>
								<span>{date}</span>
								<MinMaxValue>
									{minValue} ~ {maxValue}
								</MinMaxValue>
							</DateTitle>
							{!isCollapsed &&
								Object.entries(groupByTime(entries)).map(([timeGroup, timeEntries]) => (
									<TimeGroup key={timeGroup}>
										<TimeTitle>{timeGroup}</TimeTitle>
										{timeEntries.map((entry) => (
											<ListItem key={entry.id} type={entry.type}>
												<Type type={entry.type}>{entry.type}</Type>
												<Value>{entry.value}</Value>
												<ButtonGroup>
													<EditButton onClick={() => handleEdit(entry)}>수정</EditButton>
													<DeleteButton onClick={() => handleDelete(entry.id)}>삭제</DeleteButton>
												</ButtonGroup>
											</ListItem>
										))}
									</TimeGroup>
								))}
						</DateGroup>
					);
				})}
			</List>
		</Container>
	);
};

export default About;
