import { purple, lightBlue } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			lighter: purple[100],
			light: purple["A100"],
			main: purple[300],
			dark: "#002884",
			contrastText: "#fff",
		},
		secondary: {
			lighter: lightBlue[100],
			light: lightBlue["A200"],
			main: lightBlue[500],
			dark: "#ba000d",
			contrastText: "#000",
		},
	},
});

export default theme;
