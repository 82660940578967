import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const { handleLogin } = useOutletContext();

	const handleSubmit = (e) => {
		e.preventDefault();
		// 실제 로그인 로직을 여기에 추가하세요.
		handleLogin(username);
		navigate('/');
	};

	return (
		<LoginContainer>
			<LoginForm onSubmit={handleSubmit}>
				<LoginTitle>Login</LoginTitle>
				<LoginInput
					type="text"
					placeholder="Username"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<LoginInput
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<LoginButton type="submit">Login</LoginButton>
			</LoginForm>
		</LoginContainer>
	);
};

const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
`;

const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2em;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const LoginTitle = styled.h2`
    margin-bottom: 1em;
    text-align: center;
`;

const LoginInput = styled.input`
    margin-bottom: 1em;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const LoginButton = styled.button`
    padding: 0.5em;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    cursor: pointer;

    &:hover {
        background-color: #555;
    }
`;

export default Login;