import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

const MainTable = ({ data, open, handleToggleRow }) => {
	const theme = useTheme();

	return (
		<>
			<TableRow sx={{ backgroundColor: theme.palette.secondary.light }}>
				<TableCell>
					<IconButton onClick={() => handleToggleRow(data.key)}>
						<ExpandMoreIcon />
					</IconButton>
				</TableCell>
				<TableCell align="center">{data.date}</TableCell>
				<TableCell align="center">{data.min}</TableCell>
				<TableCell align="center">{data.max}</TableCell>
			</TableRow>
			<TableRow sx={{ backgroundColor: theme.palette.secondary.lighter }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell align="center">시간</TableCell>
										<TableCell align="center">식사</TableCell>
										<TableCell align="center">전/후</TableCell>
										<TableCell align="center">수치</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.inside.map((insideRow) => (
										<TableRow key={insideRow.key}>
											<TableCell align="center">{insideRow.time}</TableCell>
											<TableCell align="center">{insideRow.meal}</TableCell>
											<TableCell align="center">{insideRow.beforeAfter}</TableCell>
											<TableCell align="center">{insideRow.value}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default MainTable;