import Grid from "@mui/material/Grid2";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";

import InputCard from "../../components/InputCard/InputCard";
import DiabetesValue from "../../components/InputCard/DiabetesValue";
import MealInfo from "../../components/InputCard/MealInfo";
import InsulinInjectionInfo from "../../components/InputCard/InsulinInjectionInfo";

import DiabetesValueModalComponent from "../../components/InputCard/InputModal/DiabetesValueModalComponent";
import MealInfoModalComponent from "../../components/InputCard/InputModal/MealInfoModalComponent";
import InsulinInjectionInfoModalComponent from "../../components/InputCard/InputModal/InsulinInjectionInfoModalComponent";

function Input() {
	const theme = useTheme();
	const isTabletOrLarger = useMediaQuery(theme.breakpoints.up("md"));
	const isPcOrLarger = useMediaQuery(theme.breakpoints.up("lg"));

	const [DiabetesValueModalOpen, setDiabetesValueModalOpen] = useState(false);
	const handleDiabetesValueModalOpen = () => setDiabetesValueModalOpen(true);
	const handleDiabetesValueModalClose = () => setDiabetesValueModalOpen(false);

	const [MealInfoModalOpen, setMealInfoModalOpen] = useState(false);
	const handleMealInfoModalOpen = () => setMealInfoModalOpen(true);
	const handleMealInfoModalClose = () => setMealInfoModalOpen(false);

	const [InsulinInjectionInfoModalOpen, setInsulinInjectionInfoModalOpen] = useState(false);
	const handleInsulinInjectionInfoModalOpen = () => setInsulinInjectionInfoModalOpen(true);
	const handleInsulinInjectionInfoModalClose = () => setInsulinInjectionInfoModalOpen(false);

	return (
		<>
			<Box display='flex' alignItems='left'>
				<Box ml={2} />
				<h2>당뇨 정보 기록 페이지</h2>
			</Box>

			<DiabetesValueModalComponent open={DiabetesValueModalOpen} handleClose={handleDiabetesValueModalClose} />
			<MealInfoModalComponent open={MealInfoModalOpen} handleClose={handleMealInfoModalClose} />
			<InsulinInjectionInfoModalComponent open={InsulinInjectionInfoModalOpen} handleClose={handleInsulinInjectionInfoModalClose} />

			<Box
				sx={{
					flexGrow: 1,
					p: 1,
					mx: isPcOrLarger ? 40 : isTabletOrLarger ? 20 : 0, // 모바일 이상일 때 좌우 마진을 추가
					display: "flex",
					justifyContent: "center", // 내용을 가운데 정렬
				}}
			>
				<Grid container spacing={1}>
					<Grid size={{ xs: 6, md: 6 }} sx={{ mb: 2 }}>
						<DiabetesValue handleDiabetesValueModalOpen={handleDiabetesValueModalOpen} />
					</Grid>
					<Grid size={{ xs: 6, md: 6 }} sx={{ mb: 2 }}>
						<MealInfo handleMealInfoModalOpen={handleMealInfoModalOpen} />
					</Grid>
					<Grid size={{ xs: 6, md: 6 }} sx={{ mb: 2 }}>
						<InsulinInjectionInfo handleInsulinInjectionInfoModalOpen={handleInsulinInjectionInfoModalOpen} />
					</Grid>
					<Grid size={{ xs: 6, md: 6 }} sx={{ mb: 2 }}>
						<InputCard />
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default Input;
