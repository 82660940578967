import Box from "@mui/material/Box";

import DiabetesTable from "../../components/DiabetesTable";

function DiabetesHealthJournal() {
	return (
		<>
			<Box display='flex' alignItems='left'>
				<Box ml={2}></Box>
				<h2>당뇨 건강정보 수첩</h2>
			</Box>
			<DiabetesTable />
		</>
	);
}

export default DiabetesHealthJournal;
