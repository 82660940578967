import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function NotFound() {
	return (
		<NotFoundContainer>
			<NotFoundTitle>404</NotFoundTitle>
			<NotFoundText>페이지를 찾을 수 없습니다.</NotFoundText>
			<NotFoundLink to='/'>홈으로 돌아가기</NotFoundLink>
		</NotFoundContainer>
	);
}

const NotFoundContainer = styled.div`
	text-align: center;
	margin-top: 50px;
	font-family: "Arial", sans-serif;
	color: #333;
`;

const NotFoundTitle = styled.h1`
	font-size: 6em;
	margin-bottom: 20px;
	color: #e74c3c;
`;

const NotFoundText = styled.p`
	font-size: 1.5em;
	margin-bottom: 20px;
`;

const NotFoundLink = styled(Link)`
	font-size: 1.2em;
	color: #3498db;
	text-decoration: none;
	border: 2px solid #3498db;
	padding: 10px 20px;
	border-radius: 5px;
	transition: background-color 0.3s, color 0.3s;

	&:hover {
		background-color: #3498db;
		color: white;
	}
`;

export default NotFound;
