import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	background-color: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
	margin-bottom: 20px;
	color: #333;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
`;

const FormGroup = styled.div`
	margin-bottom: 15px;
`;

const Label = styled.label`
	display: flex;
	flex-direction: column;
	font-size: 16px;
	color: #333;
`;

const Input = styled.input`
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 16px;
`;

export const Select = styled.select`
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 16px;
`;

const LargeInput = styled(Input)`
	font-size: 20px;
	padding: 10px;
`;

const Button = styled.button`
	padding: 10px;
	border: none;
	border-radius: 5px;
	background-color: #333;
	color: white;
	font-size: 16px;
	cursor: pointer;

	&:hover {
		background-color: #555;
	}
`;

const Subtitle = styled.h3`
	margin-top: 20px;
	color: #333;
`;

const List = styled.ul`
	list-style: none;
	padding: 0;
	width: 100%;
	max-width: 400px;
`;

const ListItem = styled.li`
	padding: 10px;
	border-bottom: 1px solid #ccc;
	font-size: 16px;
	color: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => (props.type === "식전" ? "#e0f7fa" : "#e8f5e9")};
`;

const Type = styled.span`
	font-size: 14px;
	color: ${(props) => (props.type === "식전" ? "blue" : "green")};
	padding: 5px 10px;
	border-radius: 5px;
	display: inline-block;
`;

const Value = styled.span`
	font-size: 20px;
	color: #333;
	font-weight: bold;
`;

const DateGroup = styled.div`
	margin-bottom: 20px;
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const DateTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	color: #333;
	cursor: pointer;
`;

const MinMaxValue = styled.span`
	font-size: 20px;
	color: #333;
	font-weight: bold;
`;

const TimeGroup = styled.div`
	margin-left: 20px;
	margin-bottom: 10px;
`;

const TimeTitle = styled.h5`
	margin-bottom: 5px;
	color: #666;
`;

const ButtonGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const EditButton = styled.button`
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	background-color: #333;
	color: white;
	font-size: 12px;
	cursor: pointer;
	margin-bottom: 5px;

	&:hover {
		background-color: #555;
	}
`;

const DeleteButton = styled.button`
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	background-color: red;
	color: white;
	font-size: 12px;
	cursor: pointer;

	&:hover {
		background-color: darkred;
	}
`;

export {
	Container,
	Title,
	Form,
	FormGroup,
	Label,
	Input,
	LargeInput,
	Button,
	Subtitle,
	List,
	ListItem,
	Type,
	Value,
	DateGroup,
	DateTitle,
	MinMaxValue,
	TimeGroup,
	TimeTitle,
	ButtonGroup,
	EditButton,
	DeleteButton,
};
