import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

function DiabetesValue({ handleDiabetesValueModalOpen }) {
	const handleClick = () => {
		console.log("혈당수치 Card clicked!");
		handleDiabetesValueModalOpen();
	};

	return (
		<Card elevation={3} >
			<CardActionArea onClick={handleClick}>
				<CardMedia component='img' height='100' image='./assets/BloodGlucoseMeter.png' alt='BloodGlucoseMeter' sx={{ height: 'auto' }} />
				<CardContent>
					<Typography gutterBottom variant='h6' component='div'>
						혈당 수치
					</Typography>
					<Typography variant='body2' sx={{ color: "text.secondary" }}>
						현재 수치는? 두구두구!
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default DiabetesValue;
