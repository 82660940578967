import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Nav from "../components/Nav";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../utils/theme";

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userName, setUserName] = useState("");

	const handleLogin = (username) => {
		setIsAuthenticated(true);
		setUserName(username);
	};

	const handleLogout = () => {
		setIsAuthenticated(false);
		setUserName("");
	};

	return (
		<ThemeProvider theme={theme}>
			<Nav isAuthenticated={isAuthenticated} userName={userName} onLogout={handleLogout} />
			<Outlet context={{ handleLogin }} />
		</ThemeProvider>
	);
}

export default App;
